// Library Import
import { useState } from 'react'
import Image from 'next/image'
import { AppBar, Box, Toolbar } from '@mui/material'

// File Import
import Helpers from '@/helpers'
import CONFIGS from '@/configs'
import { cn } from '@/lib/utils'
import useResponsive from '@/hooks/responsive'
import { useAuth } from '@/api/api-hooks/auth'
import CustomLink from '@/custom-components/CustomLink'
import GoToDirectory from '@/custom-components/GoToDirectory'
import RenderHeaderMenu from '@/components/layout/includes/RenderHeaderMenu'
import MobileMenus from '@/components/layout/includes/mobile-menu/MobileMenus'
import TailwindContainer from '@/custom-components/containers/TailwindContainer'

import PTMLogo from '~/public/assets/images/logos/paytome.svg'
import headerStyles from '~/styles/scss/header/Header.module.scss'

const dashboardRedirect = CONFIGS.myAccountUrl || ''

const Header = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  const { authenticated, logout } = useAuth()
  const { tabLandscape } = useResponsive()

  const dashboardLink = e => {
    e.preventDefault()
    Helpers.redirectToDashboard()
  }

  const processSignOut = e => {
    e.preventDefault()
    logout.mutateAsync({}).then(res => {
      if (res.statusCode === 202) {
        GoToDirectory(`${CONFIGS.authUrl}/login`)
      }
    })
  }

  return (
    <AppBar
      color='inherit'
      position='sticky'
      className={cn('', headerStyles['headerWr_nav'], headerStyles[`header-home-wr`])}
    >
      <div className={cn(headerStyles[`headerWr`])}>
        <div className={cn('relative z-[9999]', headerStyles['header_sticky_style'])}>
          <TailwindContainer className={'relative'}>
            <Toolbar
              disableGutters
              className={cn('justify-between xl:justify-start', headerStyles['headerMinHeight'])}
              sx={{ justifyContent: tabLandscape ? 'space-between' : 'start' }}
            >
              <Box className={headerStyles[`logo-area-wr`]} onMouseEnter={() => setActiveIndex(0)}>
                <CustomLink href={'/'}>
                  <div className={'aspect-h-4 aspect-w-16 w-[172px] overflow-hidden lg:w-[200px]'}>
                    <Image
                      priority
                      fill={true}
                      quality={100}
                      src={PTMLogo}
                      alt={'PayToMe.co'}
                      className={'h-full w-full object-cover object-center'}
                    />
                  </div>
                </CustomLink>
              </Box>

              <div
                className={'ml-auto hidden items-center justify-end gap-0 lg:min-w-[500px] xl:!flex 2xl:min-w-[840px]'}
              >
                <RenderHeaderMenu activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
              </div>

              <div className={'flex justify-start gap-4'}>
                <div className={'hidden xl:!block'}>
                  <div onMouseEnter={() => setActiveIndex(0)}>
                    {authenticated ? (
                      <div className={headerStyles['sign_area']}>
                        <div className={headerStyles['singIn']}>
                          <a href={`/logout`} className='ptm-custom-link' onClick={e => processSignOut(e)}>
                            Sign Out
                          </a>
                        </div>
                        <div className={headerStyles['singUp']}>
                          <a
                            href={`/dashboard`}
                            onClick={e => dashboardLink(e)}
                            className={`ptm-custom-link btn-ptm-default ${headerStyles['singUp_btn-ptm-default']}`}
                          >
                            Dashboard
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className={headerStyles['sign_area']}>
                        <div className={headerStyles['singIn']}>
                          <CustomLink href={`${CONFIGS.authUrl}/login`}>Sign In</CustomLink>
                        </div>
                        <div className={headerStyles['singUp']}>
                          <CustomLink
                            href={'/pricing'}
                            className={`btn-ptm-default ${headerStyles['singUp_btn-ptm-default']}`}
                          >
                            Get Started
                          </CustomLink>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className={'xl:hidden'}>
                  <MobileMenus
                    dashboardRedirect={dashboardRedirect}
                    authenticated={authenticated}
                    processSignOut={processSignOut}
                  />
                </div>
              </div>
            </Toolbar>
          </TailwindContainer>
        </div>
      </div>
    </AppBar>
  )
}

export default Header
