export const ROUTES = Object.freeze({
  HOME: '/',
  ABOUT: '/about',
  PRICING: '/pricing',
  TEXT_TO_PAY: '/text-to-pay',
  PAYMENT_LINKS: '/payment-links',
  DIGITAL_INVOICING: '/digital-invoice-platform',
  ONLINE_INVOICING: '/online-invoicing',
  ACH_TRANSFER: '/ach-transfer',
  E_CHECK: '/e-check',
  IDENTITY_VERIFICATION: '/identity-verification',
  KYC: '/kyc-verification',
  KYB: '/kyb-verification',
  ONLINE_CHECKBOOK: '/online-checkbook',
  ONLINE_PAYMENTS: '/online-payments',
  FREE_INVOICE_GENERATOR: '/invoice-generator',
  ONLINE_CHECKOUT_OPTIONS: '/online-checkout-options',
  BANK_VERIFICATION: '/bank-account-verification'
} as const)
